import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import unroverlogo from './unroverlogo.svg'

function App() {
	return (
		<div className="text-center text-secondary mt-5">
			<img alt="unrover" src={unroverlogo} width="200" height="200" className="img-fluid" />
			<h4 className="mx-auto">Under Construction!</h4>
			<p>contact@unrover.com</p>
		</div>
	);
}

export default App;
